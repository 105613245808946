import Typed from "typed.js";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
////////////////!DOM Manipulation
const checkbox = document.querySelector("input[name=theme]");
const svg = document.getElementById("logo");

var lastScrollTop = 0;
var navbar = document.getElementById("navbar");
var typed = new Typed("#typed", {
  strings: ["I Make Websites^500 ", "I Design Websites^500 ", "I'm a Video Editor^500", "I'm a Comic book nerd^500", "I Copy and Paste Code^500"],
  smartBackspace: true, // Default value
  typeSpeed: 50,
  loop: true,
});
//* navbar scroll effect
window.addEventListener("scroll", function () {
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  if (scrollTop > lastScrollTop) {
    navbar.style.top = "-100px";
  } else {
    navbar.style.top = "0";
  }
  lastScrollTop = scrollTop;
});
//* White/Black Theme Toggle
checkbox.addEventListener("change", function () {
  if (this.checked) {
    trans();
    document.documentElement.setAttribute("data-theme", "white");
  } else {
    trans();
    document.documentElement.setAttribute("data-theme", "dark");
  }
});
//* Transition
let trans = () => {
  document.documentElement.classList.add("transition");
  window.setTimeout(() => {
    document.documentElement.classList.remove("transition");
  }, 1000);
};
////////////////!DOM Manipulation
